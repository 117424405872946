<template>
	<div class="app-container">
		<div class="filter-container">
			<!-- 搜索 -->
			<!-- <div class="filter-item">
                <label class="label">关键字:</label>
                <el-input v-model="searchKey" style="width: 200px;" placeholder="菜单名称"></el-input>
			</div>-->
			<!-- button -->
			<div class="filter-item">
				<!-- <el-button type="primary" style="margin-left:30px;width:90px;" @click="getMenlist">查询</el-button> -->
				<button-permissions :datas="'addAuthorities'">
					<el-button type="primary" style="width:100px;" @click="add">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table
				:data="jurisdictionList"
				style="width: 100%;margin-bottom: 20px;"
				row-key="id"
				border
				:tree-props="{children: 'subMenus'}"
			>
				<el-table-column prop="authorityName" label="名称" width="320"></el-table-column>
				<el-table-column prop="id" label="id"></el-table-column>
				<el-table-column prop="menuUrl" label="路径" width="220"></el-table-column>
				<el-table-column prop="authority" label="权限配置" width="220"></el-table-column>
				<el-table-column prop="roles" label="是否是按钮" width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.menuType == '1'">是</span>
						<span v-else>否</span>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="操作" width="220">
					<template slot-scope="scop">
						<div style="display:flex;">
							<button-permissions :datas="'addAuthorities'">
								<div class="table-button" @click="edit(scop.row)">编辑</div>
							</button-permissions>
							<button-permissions :datas="'editAuthorities'">
								<div class="table-button" @click="addChildren(scop.row)">添加下级</div>
							</button-permissions>
							<button-permissions :datas="'deleteAuthorities'">
								<div class="table-button" style="color:#F56C6C" @click="deleteItem(scop.row.id)">删除</div>
							</button-permissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 新增/编辑弹框 -->
		<el-dialog :visible.sync="jurVisable" width="50%" :title="jurTitle" :show-close="false">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm"
					ref="ruleForm"
					label-width="100px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						label="菜单名称:"
						prop="authorityName"
						:rules="[{required:true,message:'菜单名称不能为空',trigger: 'blur'}]"
					>
						<el-input
							v-model="ruleForm.authorityName"
							placeholder="最多输入16个字"
							maxlength="16"
							clearable
							style="width:300px"
						></el-input>
					</el-form-item>
					<el-form-item label="菜单路径:" prop="menuUrl">
						<el-input v-model="ruleForm.menuUrl" placeholder="请输入对应的路由地址" clearable style="width:300px"></el-input>
					</el-form-item>
					<el-form-item label="权限配置:" prop="authority">
						<el-input v-model="ruleForm.authority" placeholder="请输入权限配置" clearable style="width:300px"></el-input>
					</el-form-item>
					<el-form-item label="父级名称:" prop="ParentName" v-if="ruleForm.ParentName">
						<el-input v-model="ruleForm.ParentName" disabled style="width:300px"></el-input>
					</el-form-item>
					<el-form-item label="是否是按钮:" prop="menuType">
						<el-switch v-model="ruleForm.menuType"></el-switch>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="jurVisable=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange()" :loading="loading">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	Mallalljurisdict
} from "@/api/TurnTomySelf.js";
import {
	addAuthorities,
	editAuthorities,
	deleteAuthorities
} from '@/api/goods.js'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			jurisdictionList: [], //菜单数据
			searchKey: "", //关键字
			jurTitle: "新增菜单", //新增/编辑
			jurVisable: false, //弹窗开关
			ruleForm: {
				//弹窗表单数据
				id: "", //菜单ID
				authorityName: "", //菜单名称
				menuUrl: "", //菜单路径
				authority: '', //权限配置
				parentId: "", //父级ID
				ParentName: "", //父级菜单名称
				menuType: false, //是否是按钮
			},
			loading: false, //按钮加载状态
		};
	},
	created () {
		this.getMenlist();
	},
	methods: {
		//获取所有菜单
		async getMenlist () {
			try {
				let data = {
					roleId: 0,
				};
				let result = await Mallalljurisdict(data);

				if (result.success) {
					// this.jurisdictionList = this.filterChecked(result.data);
					// console.log("后台提供的所有菜单", this.jurisdictionList);
					this.jurisdictionList = result.data;
				}
			} catch (err) {
				console.log(err);
			}
		},
		filterChecked (list, record) {
			return list.map((item) => {
				if (record) {
					var obj = JSON.parse(JSON.stringify(record));
					item.parentList = obj.parentList || [];
					item.parentList.push(obj.id);
					item.parentId = obj.id;
				}
				if (item.IsCheck) {
					this.jurisdictionList.push(item.id);
				}
				if (item.ChildMenuDtoList && item.ChildMenuDtoList.length) {
					this.filterChecked(item.ChildMenuDtoList, item);
				}
				return item;
			});
		},
		// 数组去重
		unique (arr) {
			const res = new Map();
			return arr.filter((arr) => !res.has(arr) && res.set(arr, 1));
		},
		//编辑
		edit (item) {
			console.log(item);
			this.jurTitle = "编辑菜单";
			this.formatForm(item);
			this.ruleForm.ParentName = '';
			this.jurVisable = true;
		},
		//格式化表单数据
		formatForm (item) {
			this.ruleForm.id = item.id; //菜单ID
			this.ruleForm.authorityName = item.authorityName; //菜单名称
			this.ruleForm.menuUrl = item.menuUrl; //菜单路径
			this.ruleForm.authority = item.authority; //菜单路径
			if (item.menuType == 0 || !item.menuType) { //是否是按钮
				this.ruleForm.menuType = false;
			} else {
				this.ruleForm.menuType = true;
			}
			this.ruleForm.parentId = item.parentId; //父级ID
		},
		//删除
		deleteItem (id) {
			console.log(id);
			this.$confirm('删除后将无法恢复, 是否继续?', '删除菜单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let result = await deleteAuthorities({
					id: id
				});
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
				this.getMenlist();
			}).catch(() => {
				console.log('取消删除');
			});
		},
		//添加
		add () {
			this.jurTitle = "新增菜单";
			this.jurVisable = true;
			this.formatForm({});
			this.ruleForm.ParentName = '';
		},
		//添加下级
		addChildren (item) {
			this.jurTitle = "新增下级菜单";
			this.jurVisable = true;
			this.formatForm({});
			this.ruleForm.ParentName = item.authorityName;
			this.ruleForm.parentId = item.id;
		},
		//弹窗确定按钮
		async saveChange () {
			var _this = this;
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					try {
						this.loading = true;
						if (_this.ruleForm.menuType) {
							_this.ruleForm.menuType = 1;
						} else {
							_this.ruleForm.menuType = 0;
						}
						let result = {};
						if (_this.ruleForm.id != undefined) {
							result = await editAuthorities(_this.ruleForm);
						} else {
							result = await addAuthorities(_this.ruleForm);
						}

						if (result.success) {
							_this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功!'
							});
							_this.jurVisable = false;
							_this.getMenlist();
						} else {
							_this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
						this.loading = false
					} catch (error) {
						console.log(error);
						this.loading = false
					}
				} else {
					return false;
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}
</style>